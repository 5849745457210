import React, { Component } from 'react';
import ListSection from './components/ListSection';

class Description extends Component {

  render() {
    return(
      <div style={{margin:'8%'}}> 
        <h2>About this Property</h2>
      This condo is located on the East end of 30A in Seacrest, FL, between Rosemary and Alys Beach. 
      <br></br><br></br>
      This condo offers a king size bed and one set of full over full bunk beds. 
      The condo can easily sleep 4 (up to 6 with small children). 
      <br></br><br></br>
      This condo is located in Building G at The Village of South Walton. 
      The beach is a short walk away and a seasonal shuttle provides service running from right next to the condo building to the beach.
      <br></br><br></br>
      The private beach access is open from 6am to 10pm and is located at The Villas at Sunset Beach, just across the street from the Seacrest community.
      <br></br><br></br>
      The unit has a kitchenette stocked with a Keurig, microwave, toaster oven, dishes, and a medium-sized refrigerator.
      <br></br><br></br>
      This second floor unit has balcony access overlooking the pool. 
      Enjoy a morning coffee or relaxing evening listening to the seasonal live music at Peddlers Pavillion. 
      The building has an elevator and luggage carts, for convenience.
      <br></br><br></br>
      The Villages provides 2 community pools, one of which is heated, a hot tub, and outdoor grills. 
      These are conveniently located right by the building.
      <br></br><br></br>
      We provide small sets of shampoo, soap, toilet paper and paper towels. 
      There is a grocery store within the complex that conveniently provides other necessities you may need.
      A laundromat with credit card operated washers and dryers is located on the ground floor of the building.
      <br></br><br></br>
      There is a large parking lot which guests are welcome to utilize. 
      There is no assigned parking and availability is first come.
      
      <ListSection header='The following are prohibited in the parking lot:' items={['No golf carts or LSV','No large trucks (box trucks, etc.)','No boats, jet skis/wave runners, boat trailers','No Motorhomes']} />
      
      <div>
      This condo is a smoke free facility.
      Complimentary Wi-Fi is provided by The Village of South Walton complex, but is not managed by the unit. As it is a shared service throughout the entire complex, reliability is not guaranteed. It is advised to have a personal hotspot if internet access is a requirement.
      </div>
      <br></br>
      <div>
      Beach chairs are serviced through La Dolce Vita. After booking, it is advised to reserve any desired chair rentals as soon as possible, as there is limited availability. Renting chairs also reserves space on the private beach, which services all Seacrest rental properties. Without chair reservation, beach space is on a first come basis. You can reserve chairs on their website or in person at the beach. On the site, select 30A South Walton, then select The Villas at Sunset Beach and Seacrest Beach.
      </div>
      <a
          className="App-link"
          href="https://www.ldvbeach.com/store/category/beach-chairs-and-umbrellas/"
          target="_blank"
          rel="noopener noreferrer"
        >
          La Dolce Vita
        </a>
        <br></br><br></br>
      <div>
      We recommend that you purchase travel insurance, we are not able to issue refunds for travel delays, illness or weather. We are only able to issue refunds in accordance to our posted cancellation policy.
      </div>
      
      </div>

    )
  }

}

export default Description