
import { addDays } from 'date-fns';
import { useState } from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import { DateRangePicker } from 'react-date-range';
import { DateRange } from 'react-date-range';

//import data from '../sampleData/CalendarInfo.json';

import AWS from 'aws-sdk';


AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIAYISBBB3MYFRWICRP',
    secretAccessKey: 'GCPOkWewnwpdURJD45hnlhgVItty0E8Rb+xZ/aSz',
  });


function RateCalculatorFromFile() {

    var calendarData;

    const s3 = new AWS.S3();

    const params = {
      Bucket: 'sopcalendardata',
      Key: 'CalendarInfo.json',
    };

    
    s3.getObject(params, function(err, data) {
      if (err) {
        console.log(err);
      } else {
        // The file data is available in the `data.Body` property.
        calendarData = JSON.parse(data.Body.toString());
        //console.log(calendarData);
      }
    });


    const [totalCost, setTotalCost] = useState(0);

    const [totalCostBreakdown, setTotalCostBreakdown] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            weekdayRate: 0,
            weekdayNights: 0,
            weekendRate: 0,
            weekendNights: 0,
            avgDailyRate: 0,
            cleaningFee: 0,
            taxes: 0,
            error: ""
        }
    );

    const [feeRates, setFeeRates] = useState(
        {
            baseRate: 350,
            //weekdayDiscountPctPeakSeason: 7,
            //weekdayDiscountPctOffSeason: 30,
            dollarOffWeekday: 25,
            discountPctOffSeason: 30,
            offSeasonMonths: [1,2,4,9,11,12],
            cleaningFee: 175,
            taxPct: 12
        }
    );

/*     const [overrideRates, setOverrideRates] = useState([
            {
            baseRate: 0,
            startDate: new Date(2024,0,1),
            endDate: new Date(2024,0,1)
            }
     ]); */

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 3),
          key: 'selection'
        }
      ]);


    const dateOverrideRates = [

        //holidays
        {overrideWeekendRate: 425, overrideWeekdayRate: 425, startDate: new Date(2024,7-1,1) , endDate: new Date(2024,7-1,7)},
        {overrideWeekendRate: 375, overrideWeekdayRate: 375, startDate: new Date(2024,5-1,24) , endDate: new Date(2024,5-1,29)},

        {overrideWeekendRate: 125, overrideWeekdayRate: 125, startDate: new Date(2024,1-1,1) , endDate: new Date(2024,2-1,29)},
        {overrideWeekendRate: 275, overrideWeekdayRate: 250, startDate: new Date(2024,3-1,1) , endDate: new Date(2024,5-1,22)},
        {overrideWeekendRate: 350, overrideWeekdayRate: 325, startDate: new Date(2024,5-1,23) , endDate: new Date(2024,8-1,10)},
        {overrideWeekendRate: 250, overrideWeekdayRate: 225, startDate: new Date(2024,8-1,11) , endDate: new Date(2024,10-1,31)},
        {overrideWeekendRate: 200, overrideWeekdayRate: 175, startDate: new Date(2024,11-1,1) , endDate: new Date(2024,11-1,30)},
        {overrideWeekendRate: 175, overrideWeekdayRate: 150, startDate: new Date(2024,12-1,1) , endDate: new Date(2024,12-1,31)},

        {overrideWeekendRate: 125, overrideWeekdayRate: 125, startDate: new Date(2025,1-1,1) , endDate: new Date(2025,2-1,29)},
    ];

    const blockedDates = [
        {dateBegin: new Date(2024,3-1,13), dateEnd: new Date(2024,3-1,18)},
        {dateBegin: new Date(2024,3-1,28), dateEnd: new Date(2024,4-1,3)},
        {dateBegin: new Date(2024,4-1,3), dateEnd: new Date(2024,4-1,8)},
        {dateBegin: new Date(2024,6-1,6), dateEnd: new Date(2024,6-1,9)}

    ];

    function emailInquiry(){
        const newline = "%0D%0A";
        
        var emailBody = "Please Complete the top section and attach a copy of the Booking Guest's valid Drivers License."
        emailBody = emailBody + newline;

        emailBody = emailBody + newline + "Booking Guest Name: ";
        emailBody = emailBody + newline + "Total Number of Guests: ";
        emailBody = emailBody + newline + "Number of Guests under 12 yrs old: ";
        emailBody = emailBody + newline;
        emailBody = emailBody + newline + "Booking Inquiry Details";
        emailBody = emailBody + newline + "Checkin Date: " + totalCostBreakdown.startDate.toDateString();
        emailBody = emailBody + newline + "Checkin Date: " + totalCostBreakdown.endDate.toDateString();
        emailBody = emailBody + newline + "Total Cost Quote: $" + totalCost;

        // emailBody = emailBody + newline + "Weekday Rate: $" + totalCostBreakdown.weekdayRate.toFixed(2) + " x " + totalCostBreakdown.weekdayNights;
        // emailBody = emailBody + newline + "Weekend Rate: $" + totalCostBreakdown.weekendRate.toFixed(2) + " x " + totalCostBreakdown.weekendNights;
        emailBody = emailBody + newline + "Nightly Rate: $" + totalCostBreakdown.avgDailyRate.toFixed(2);
        emailBody = emailBody + " x " + (totalCostBreakdown.weekdayNights + totalCostBreakdown.weekendNights).toString();
        
        emailBody = emailBody + newline + "Cleaning: $" + totalCostBreakdown.cleaningFee.toFixed(2);
        emailBody = emailBody + newline + "Taxes: $" + totalCostBreakdown.taxes.toFixed(2);

        window.open('mailto:sliceofparadise30a@gmail.com?subject=Booking Inquiry&body='+emailBody);
    }

    function calculateRate() {

        var calendarDataFromFile = calendarData;

        var total = 0;
        var weekdayRate = 0;
        var weekdayNights = 0;
        var weekendRate = 0;
        var weekendNights = 0;
        var cleaningFee = 0;
        var tax = 0;
        var errorText = "";
        var errorOccurred = false;
        var avgDailyRate = 0;

        var daydiff = state[0].endDate - state[0].startDate;
        var days = Math.ceil(daydiff / (1000 * 3600 * 24));

        
        if(days < 3)
        {
            errorText = "You must select at least 3 nights (4 days).";
            errorOccurred = true;
        }
        if(state[0].startDate < new Date().setHours(0,0,0,0)){
            errorText = "You cannot select a date in the past.";
            errorOccurred = true;
        }
     

        if(!errorOccurred){

            //loop each selected date
            //total by each day summed
            var totalRateForDateRange = 0;
            var thisDayRate = 400;
            errorOccurred = false;
            for (let d = state[0].startDate; d < state[0].endDate && errorOccurred === false; d = addDays(d, 1))
            {
                
                for (let i = 0; i < calendarDataFromFile.length && errorOccurred === false; i++)
                {
                    var calendarDay = calendarDataFromFile[i];
                    if(d.getUTCMonth() + 1 === calendarDay.month
                        && d.getUTCDate() === calendarDay.day
                        && d.getUTCFullYear() === calendarDay.year
                        )
                        {
                            thisDayRate = calendarDay.rate;
                            if(calendarDay.isBooked)
                            {
                                errorText = "The selected dates are not available.";
                                errorOccurred = true;
                            }
                            break;
                        }
                }

                
                totalRateForDateRange = totalRateForDateRange + thisDayRate;
            }


            for (let d = state[0].startDate; d < state[0].endDate; d = addDays(d, 1)) {
                if([5,6].includes(d.getDay())){
                    weekendNights++;
                } else {
                    weekdayNights++;
                }
            }

            cleaningFee = 175;

            //total = (weekdayRate*weekdayNights)+(weekendRate*weekendNights);
            total = totalRateForDateRange;
            total = total + cleaningFee;
            tax = feeRates.taxPct*.01*total;
            //tax = tax.toFixed(2);
            //tax = Number(tax);
            //total = total.toFixed(2);
            //total = Number(total);
            total = total + tax;

            avgDailyRate = totalRateForDateRange/(weekdayNights+weekendNights);
    
        }

        setTotalCost(total);
    
        setTotalCostBreakdown({
            startDate: state[0].startDate,
            endDate: state[0].endDate,
            weekdayRate: weekdayRate,
            weekdayNights: weekdayNights,
            weekendRate: weekendRate,
            weekendNights: weekendNights,
            cleaningFee: cleaningFee,
            taxes: tax,
            error: errorText,
            avgDailyRate: avgDailyRate
        });
    }

    return(
        <div>
            {/* <DateRangePicker
                    onChange={item => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    /> */}

            <DateRange
            editableDateInputs={true}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
            />

            <div>
                <button onClick={calculateRate}>Calculate Cost</button>
            </div>
            
            <div style={{color:'red'}}>{totalCostBreakdown.error}</div>

            <div>Total Cost: ${totalCost.toFixed(2)}</div>
            <div>
                {/* <div>Cost Breakdown:</div> */}
                {/* <div>Checkin: {totalCostBreakdown.startDate.toDateString()}</div> */}
                {/* <div>Checkout: {totalCostBreakdown.endDate.toDateString()}</div> */}
                {/* <div>Weekday Rate: {totalCostBreakdown.weekdayRate.toFixed(2)} x {totalCostBreakdown.weekdayNights}</div> */}
                {/* <div>Weekend Rate: {totalCostBreakdown.weekendRate.toFixed(2)} x {totalCostBreakdown.weekendNights}</div> */}
                <div>Nightly Rate: {totalCostBreakdown.avgDailyRate.toFixed(2)} x {totalCostBreakdown.weekdayNights + totalCostBreakdown.weekendNights}</div>
                <div>Cleaning: {totalCostBreakdown.cleaningFee.toFixed(2)}</div>
                <div>Taxes: {totalCostBreakdown.taxes.toFixed(2)}</div>     
            </div>

            <div>
                <button onClick={emailInquiry}>Email Inquiry</button>
            </div>

        </div>
    );
  }

export default RateCalculatorFromFile