
import { addDays } from 'date-fns';
import { useState } from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import { DateRangePicker } from 'react-date-range';
import { DateRange } from 'react-date-range';

//import data from '../sampleData/CalendarInfo.json';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import React, { useRef } from 'react'
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import AWS from 'aws-sdk';


AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIAYISBBB3MYFRWICRP',
    secretAccessKey: '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',
  });


function UpdateRateCalendar(props) {

    const gridRef = useRef();
    //const gridApi = gridRef.current;

    // Row Data: The data to be displayed.
    /*
    const [rowData, setRowData] = useState([
        { month: 1, day: 1, year: 2024, rate: 300, isBooked: true },
        { month: 1, day: 2, year: 2024, rate: 300, isBooked: false },
        { month: 1, day: 3, year: 2024, rate: 300, isBooked: false },
    ]);
    */

    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "year", width: 75, resizable: false, suppressMovable: true },
        { field: "month", width: 75, resizable: false, suppressMovable: true },
        { field: "day", width: 75, resizable: false, suppressMovable: true },
        { field: "rate", width: 75, editable: true, resizable: false, suppressMovable: true },
        { field: "isBooked", width: 100, editable: true, resizable: false, suppressMovable: true }
    ]);

    var calendarData;

    const s3 = new AWS.S3();

    const params = {
      Bucket: 'sopcalendardata',
      Key: 'CalendarInfo.json',
    };

    if(rowData && rowData.length === 0)
    {

        s3.getObject(params, function(err, data) {
        if (err) {
            console.log(err);
        } else {
            // The file data is available in the `data.Body` property.

            calendarData = JSON.parse(data.Body.toString());

            
            if(rowData != null && rowData.length === 0)
            {
                setRowData(calendarData);
            }

        }
        });

    }


    console.log("Rendering UpdateRateCalendar");
    //setRowData(calendarData);

    const handleFileUpload = async () => {
        //const s3 = new AWS.S3();
    
        //gridRef.current?.api.
        let myRowData = [];
        gridRef.current.api.forEachNode(node => myRowData.push(node.data));
        //console.log(myRowData);
        //console.log(JSON.stringify(myRowData));
        let updateString = JSON.stringify(myRowData);
        //console.log(updateString);

        const uparams = {
          Bucket: 'sopcalendardata',
          Key: 'CalendarInfo.json',
          //Body: JSON.stringify(calendarData),
          Body: updateString,
        };
    
        //console.log(uparams.Body);
        //console.log(gridRef.current);
        await s3.putObject(uparams).promise();
    
        console.log('File uploaded successfully!');

        setRowData([]);

      };
    

    function updateAWS(){
        setRowData()
        handleFileUpload();
    }
       


    return(
        <div>
        
            <div
                className="ag-theme-quartz" // applying the grid theme
                style={{ height: 500 }} // the grid will fill the size of the parent container
                >
                <AgGridReact ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                />
            </div>

            <div>
                <button onClick={updateAWS}>Update Calendar</button>
            </div>

        </div>
    );
}

export default UpdateRateCalendar