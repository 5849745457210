
function ListSection({ header,items }) {

    return(
        <div>
            <h3>{header}</h3>
            {/* <ul>{items.length > 0 && items.map((item) => <li>{item.name} </li>)}</ul> */}
            <ul style={{textAlign: "center", padding:0, listStylePosition: "inside", listStyleType: "none"}}>{items.length > 0 && items.map((item) => <li key={item}>{item}</li>)}</ul>
        </div>
    );
  }

export default ListSection