import logo from './images/IMG_4412_small.jpg';

import imgCondo1 from './images/279_1.jpg';
import imgCondo2 from './images/279_2.jpg';
import imgCondo3 from './images/279_3.jpg';
import imgCondo4 from './images/279_4.jpg';
import imgCondo5 from './images/279_5.jpg';
import imgCondo6 from './images/279_6.jpg';
import imgCondo7 from './images/279_7.jpg';
import imgCondo8 from './images/279_8.jpg';
import imgCondo9 from './images/279_9.jpg';

import imgBeach from './images/Beach.jpg';
import imgPools from './images/Pools.jpg';


import Description from './Description.js';
import Book from './Book.js';
import ListSection from './components/ListSection.js';

import ImageViewer from './components/ImageViewer.js';
import GoogleCalendar from './components/GoogleCalendar.js';

import RateCalculator from './components/RateCalculator.js';
import RateCalculatorFromFile from './components/RateCalculatorFromFile.js';

import UpdateRateCalendar from './components/UpdateRateCalendar.js';

import './App.css';

import { useState } from 'react';
//import { useId } from 'react';

// const amenityItems = [{ name: 'item 1' }, { name: 'item2' }];
const amenityItems = ['Beach Access', 'Beach Tram','Heated Pool','Hot Tub','King Bed','Full Over Full Bunk','Balcony','Kitchenette','Dining Table','Refridgerator','TV','Keurig Coffee Maker','Laundromat','Gas Grills'];

var showEdit = true;


function App() {

  const [loginVisible, setloginVisible] = useState(false); 

  const [userName, setUserName] = useState('');
  const [pw, setPw] = useState('');
  const [mfa, setMfa] = useState('');

  function showLogin(){
    setloginVisible(true);
  }
  
  return (
    <div className="App">

      <header className="App-header"> 
        <img src={logo} className="App-logo" alt="logo" />
          <div>
          <h1>Slice Of Paradise 30a</h1>
          Seacrest 30a Studio Condo <br></br>
          Located between Rosemary and Alys Beach <br></br>
          Heated Pool & Beach Access<br></br>
          Located at &nbsp; 
          <a
          className="App-link"
          href="https://peddlers30a.com/"
          target="_blank"
          rel="noopener noreferrer"
          >
          Peddlers Pavillion
        </a>
          
          
          </div>

          <div>
          Sleeps up to 6
          <br></br>
          4 Adults or 2 Adults and 4 Children
        </div>
        <br></br>
        <a
          className="App-link"
          href="https://www.vrbo.com/3184888"
          target="_blank"
          rel="noopener noreferrer"
        >
          VRBO Listing
        </a>
        <br></br>
        <Book />

        {/* <GoogleCalendar/> */}

        <h3>Select Dates and Get a Quote</h3>
        <RateCalculatorFromFile/>

      </header>

      <div className="App-body">
        <br></br>
        <ImageViewer items={[imgCondo1,imgCondo2,imgCondo3,imgCondo4,imgCondo5,imgCondo6,imgCondo7,imgCondo8,imgCondo9,imgBeach,imgPools]}/>

        <ListSection header='Walking Distance to:' items={['Shopping','Restaurants','Ice Cream','Brew Pub','Bike Rentals','Grocery Store','Pool','Beach Access','Seasonal Live Music','Seasonal Fire Pit','Seasonal Beach Tram']}/>
        <ListSection header='Amenities' items={amenityItems}/>

        <Description />

        <br></br>
      </div>

      <div>
        <button onClick={showLogin}>Admin</button>
      </div>
      { loginVisible &&
        <div>
          <label>
          User: <input name="myUser" onChange={e => setUserName(e.target.value)}/>
        </label>
        <br></br>
        <label>
          Password: <input name="myPassword" onChange={e => setPw(e.target.value)}/>
        </label>
        <br></br>
        <button>Send MFA Code</button>
        <br></br>
        <label>
          Enter MFA Code: <input name="mfaCode" onChange={e => setMfa(e.target.value)}/>
        </label>
        </div>
      }
       {/* {userName !== '' && <p>Your name is {userName}.</p>}
       {pw !== '' && <p>Your Pw is {pw}.</p>} */}
      { showEdit && userName === 'jeni' && pw === 'Jeni@Sop' && mfa === '227799' &&
        
        <div>
          <UpdateRateCalendar/>
        </div>
      }
    

      <footer>
        Site Provided by JAB Software Solutions LLC
      </footer>

    </div>

  );
}

export default App;
