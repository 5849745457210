import React, { Component } from 'react';

class Book extends Component {

  render() {
    return(
        <div>Direct Book</div>
    )
  }
}

export default Book