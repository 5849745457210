import React from 'react';
import '../App.css';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();


function ImageViewer({ items }) {
    var imgs = [];
for (const item of items){
    imgs.push(<img src={item} onDragStart={handleDragStart}  alt="missing" width={"80%"} height={"80%"} />);
}

    return(
        <div>
            {/* <ul>{items.length > 0 && items.map((item) => <li>{item.name} </li>)}</ul> */}
            {/* <ul list-style={"none"}>{items.length > 0 && items.map((item) => <li display={"inline"}>
                <img src={item} alt="logo" width={"50%"} height={"50%"} />    
            </li>)}</ul> */}

            {/* {items.map((item) => <p><img src={item} classname="sliderimg" alt="logo"/></p>)} */}
           
            <AliceCarousel mouseTracking items={imgs} autoPlay={true} autoPlayInterval={5000} infinite={true} disableButtonsControls={true} />

        </div>
    );
  }

export default ImageViewer